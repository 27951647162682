<template>
	<nav class="nav nav--fixed">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon-left--white">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text white">
					내가 초대한 회원
				</span>
			</p>
			<a class="nav__btn">
			</a>
		</div>
	</nav>
	<div id="container" class="container">
		<div id="sub" class="sub mypage">
			<section class="top top--fit">
				<div class="mypage__my">
					<div class="mypage__my-info">
						<div class="mypage__my-name">
							<span>
								<strong>{{userData.nick_nm}}</strong>님이<br>
								초대한 회원수
							</span>
						</div>
					</div>
					<div class="mypage__my-point">
						<strong>{{totalItem}}</strong>
						<span>
							명
						</span>
					</div>
				</div>
			</section>
			<section class="content">
				<div class="box" v-if="mmy0113.length > 0">
					<div class="mypage__wrap">
						<div class="mypage__inner">
							<!-- 마이페이지 내가초대한 회원 상세 -->
							<ul class="info info--small">
									<li class="subject">
											<h4 class="info__subject" style="padding-left:34px">
													가입일
											</h4>
											<h4 class="info__subject">
													아이디
											</h4>
									</li>
									<li v-for="(item, index) in mmy0113" :key="index">
											<h4 class="info__title">
													{{dayjs(item.DATE).format('YYYY.MM.DD')}}
											</h4>
											<div class="info__wrap">
													<div class="info__text">
															<span class="black">{{item.JOIN_ID}}</span>
													</div>
											</div>
									</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="mypage__wrap" v-else>
						<div class="mypage__inner">
								<div class="textbox textbox--margin" style="margin-top:100px">
										<div class="textbox__icon">
												<img src="@/assets/images/icon-recommend.png" alt="찾으시는 결과가 없나요?" style="width: 44px;">
										</div>
										<h3 class="textbox__title">내가 초대한 회원이 없어요</h3>
										<p class="textbox__description"> 친구 초대하고 1000 포인트 받으세요! </p>
										<div class="textbox__notice" v-if="mmy0101.length > 0">
												내 추천인코드 : <strong class="skyblue">{{mmy0101[0].recomm_cd}}</strong>
										</div>
								</div>
						</div>
				</div>
			</section>
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder" v-if="mmy0113.length > 0">
			<div class="pager">
				<a @click="onClickStartPage">
					<i class="icon icon-first"></i>
				</a>
				<a @click="onClickPrevPage">
					<i class="icon icon-left--light"></i>
				</a>
				<a 
					v-for="index in Math.min(maxPage, 5)" 
					:key="index" 
					:class="{'active' : (page === pageOffset + index)}"
					@click="onClickMovePage(pageOffset + index)"
				>
					{{pageOffset + index}}
				</a>
				<a @click="onClickNextPage">
					<i class="icon icon-right--light"></i>
				</a>
				<a @click="onClickEndPage">
					<i class="icon icon-last"></i>
				</a>
			</div>
	</div>
	<TheNavigation></TheNavigation>
</template>

<script>
import { useStore } from "vuex"
import { computed, onMounted, ref } from "vue"
import { useRouter } from 'vue-router';
import dayjs from 'dayjs'
import "dayjs/locale/ko";

dayjs.locale("ko");

export default {
	setup: function() {
		const router = useRouter();
		const store = useStore();

		const userData = computed(() => store.state.userData);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);
		const mmy0113 = computed(() => store.state.mmy01.mmy0113);
		const mmy0101 = computed(() => store.state.mmy01.mmy0101);

		const totalItem = computed(()=> mmy0113.value.length > 0	? mmy0113.value[0].TOTAL_RECORDS : 0);
		const maxPage = computed(() => Math.ceil(totalItem.value / 10));
		const page = ref(1);
		const startPage = computed(() => (page.value - 1)/5 * 5 + 1);
		const endPage = computed(() => Math.min(startPage.value + 4, maxPage.value));
		const pageOffset = computed(() => Math.max(Math.min(page.value - 3, maxPage.value - 5), 0));

		onMounted(()=>{
			if(isLogin.value){
				store.dispatch("mmy01/fetchMmy0113", {
					proc_cd: "01",
					mem_id: userData.value.mem_token,
					pagesize: 10,
					gotopage: 1
				});
				if(mmy0101.value.length <= 0) {
					store.dispatch("mmy01/fetchMmy0101", {
						proc_cd: "01",
						mem_id: userData.value.mem_token
					});
				}
			}
		});

		const updatePage = (index) => {
			if(page.value !== index){
				store.dispatch("mmy01/fetchMmy0113", {
					proc_cd: "01",
					mem_id: userData.value.mem_token,
					pagesize: 10,
					gotopage: index
				});
				page.value = index;
			}
		}

		const onClickMovePage = (index) => {
			updatePage(index);
		}

		const onClickNextPage = () => {
			if(page.value < maxPage.value){
				updatePage(page.value + 1);
			}
		}
		const onClickPrevPage = () => {
			if(page.value > 1){
				updatePage(page.value - 1);
			}
		}	

		const onClickEndPage = () => {
			updatePage(maxPage.value);
		}
		const onClickStartPage = () => {
			updatePage(1);
		}

		const goBack = () => {
			router.go(-1);
		};

		return {
			goBack,
			dayjs,
			isLogin,
			mmy0113,
			userData,
			mmy0101,
			maxPage,
			page,
			onClickMovePage,
			onClickNextPage,
			onClickPrevPage,
			onClickEndPage,
			onClickStartPage,
			startPage,
			endPage,
			pageOffset,
			totalItem
		}
	}
}
</script>